export default function (req, res, next) {
    // Überprüfen Sie, ob der Hostname der Anforderung 'bette.hu' ist
    if (req.headers['x-forwarded-host'] === 'bette.hu') {
        // Wenn ja, leiten Sie zur neuen URL um
        res.writeHead(301, { Location: `https://www.my-bette.com${req.url}` });
        return res.end();
    }

    // Überprüfen Sie, ob die URL mit einem Trailing Slash endet
    if (req.url.endsWith('/') && req.url !== '/') {
        // Wenn ja, entfernen Sie den Trailing Slash und leiten Sie zur neuen URL um
        const urlWithoutTrailingSlash = req.url.slice(0, -1);
        res.writeHead(301, { Location: urlWithoutTrailingSlash });
        return res.end();
    }

    // Wenn nicht, fahren Sie mit der nächsten Middleware oder Route fort
    return next();
}
